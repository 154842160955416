@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap');

@layer base{
    body{
        /* @apply bg-[#2e3344]; */


        
        @apply overflow-x-hidden; 

  
    }
    html {
        @apply scroll-smooth;
      }
  
}